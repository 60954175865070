var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.lowestNitrate)?_c('v-alert',{attrs:{"type":"info","text":""}},[_vm._v("Your test results are below the threshold of any of the Scientific Studies in this database. But you may be interested in seeing the health risks of higher nitrate levels.")]):_vm._e(),(_vm.show)?_c('v-expand-transition',[_c('v-data-table',{attrs:{"headers":_vm.headersComputed,"items":_vm.articles,"hide-default-footer":"","items-per-page":20},scopedSlots:_vm._u([{key:"header.nitrateLevel",fn:function(){return [_vm._v(" Study Nitrate "),_c('br'),_vm._v(" mg/L ")]},proxy:true},{key:"item.diseases",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.diseases.join(', '))+" ")]}},{key:"item.nitrateLevel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toFixed")(item.nitrateLevel))+" ")]}},{key:"item.datePublished",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.datePublished.split('-')[0])+" ")]}},{key:"item.authorPrimary",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("author")(item.authorPrimary))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":_vm.extraArticle(item) ? 'grey' : 'primary',"icon":""},on:{"click":function($event){return _vm.showMore(item.id)}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}],null,true)})],1):_vm._e(),(_vm.showHide)?_c('v-btn',{staticClass:"text-capitalize hide-btn",attrs:{"outlined":"","color":"deep-purple accent-4","dark":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v("mdi-stethoscope")]),_vm._v(" "+_vm._s(_vm.show ? 'Hide' : 'Show')+" scientific studies applicable to my water")],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }