<template>
  <div>
    <v-alert type="info" text v-if="lowestNitrate"
      >Your test results are below the threshold of any of the Scientific
      Studies in this database. But you may be interested in seeing the health
      risks of higher nitrate levels.</v-alert
    >
    <v-expand-transition v-if="show">
      <v-data-table
        :headers="headersComputed"
        :items="articles"
        hide-default-footer
        :items-per-page="20"
      >
        <template v-slot:[`header.nitrateLevel`]>
          Study Nitrate <br />
          mg/L
        </template>
        <template v-slot:item.diseases="{ item }">
          {{ item.diseases.join(', ') }}
        </template>
        <template v-slot:item.nitrateLevel="{ item }">
          {{ item.nitrateLevel | toFixed }}
        </template>
        <template v-slot:item.datePublished="{ item }">
          {{ item.datePublished.split('-')[0] }}
        </template>
        <template v-slot:item.authorPrimary="{ item }">
          {{ item.authorPrimary | author }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
            :color="extraArticle(item) ? 'grey' : 'primary'"
            icon
            @click="showMore(item.id)"
            ><v-icon>mdi-plus-circle</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-expand-transition>
    <v-btn
      v-if="showHide"
      outlined
      class="text-capitalize hide-btn"
      @click="show = !show"
      color="deep-purple accent-4"
      dark
    >
      <v-icon>mdi-stethoscope</v-icon> {{ show ? 'Hide' : 'Show' }} scientific
      studies applicable to my water</v-btn
    >
  </div>
</template>

<script>
import article from '@/mixins/article'
export default {
  props: {
    nitrate: {},
    showHide: { type: Boolean, default: true },
    showHideDefault: { type: Boolean, default: false },
    explore: {
      type: Boolean,
      default: false
    },
    extraArticles: {
      type: Boolean,
      default: false
    }
  },
  mixins: [article],
  filters: {
    toFixed(num, places = 1) {
      if (num % 1 === 0) {
        return Number(num).toFixed(places)
      }
      return num
    },
    author(str) {
      return String(str).split(' ').pop()
    }
  },
  data: () => ({
    show: false,
    lowestNitrate: false,
    articles: [],
    headers: [
      {
        text: 'Peer-Reviewed Journal',
        value: 'journal'
      },
      {
        text: 'Study Nitrate mg/L',
        value: 'nitrateLevel',
        align: 'center'
      },
      {
        text: 'Disease(s) or Concern(s)',
        value: 'diseases'
      },
      {
        text: 'Lead Author',
        value: 'authorPrimary',
        hideMdAndDown: true
      },
      {
        text: 'Year',
        value: 'datePublished',
        hideXs: true
      },
      {
        text: 'Key',
        value: 'referenceKey',
        hideXs: true,
        align: 'center'
      },
      {
        text: 'More',
        value: 'action'
      }
    ]
  }),
  computed: {
    maxNitrate() {
      return Number(this.nitrate) + 1
    },
    headersComputed() {
      const xs = this.$vuetify.breakpoint.xs
      const lgAndUp = this.$vuetify.breakpoint.lgAndUp
      return this.headers.filter(h => {
        let res = true

        if (!lgAndUp && h.hideMdAndDown) {
          return false
        }

        if (xs && h.hideXs) {
          res = false
        }
        return res
      })
    }
  },
  mounted() {
    this.show = this.showHideDefault
  },
  methods: {
    showMore(article) {
      window.open(`/nitrate-articles/${article}`, '_articles')
    },
    getArticles() {
      let articlesFound = []
      this.lowestNitrate = false
      this.$emit('update:extraArticles', false)
      for (let article of this.nitrateArticles) {
        if (article.nitrateLevel <= this.maxNitrate) {
          articlesFound.push(article)
          if (
            article.nitrateLevel <= this.maxNitrate &&
            article.nitrateLevel > this.nitrate
          ) {
            this.$emit('update:extraArticles', true)
          }
        } else {
          this.lowestNitrate = true
        }
      }
      if (articlesFound.length) {
        this.lowestNitrate = false
      } else {
        if (this.lowestNitrate) {
          articlesFound = this.nitrateArticles
        }
      }
      articlesFound.sort((a, b) => b.nitrateLevel - a.nitrateLevel)
      this.articles = articlesFound
    },
    extraArticle(article) {
      if (article.nitrateLevel > this.nitrate) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    nitrate: {
      handler: 'getArticles',
      immediate: true
    }
  }
}
</script>

<style scoped>
.article-extra td {
  color: grey !important;
}
@media (max-width: 600px) {
  .hide-xs {
    display: none;
  }
  .hide-btn {
    font-size: 12px;
  }
}
@media (max-width: 450px) {
  .hide-btn {
    font-size: 10px;
  }
}
</style>
